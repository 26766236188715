<template>
  <div>
      <div :class="[visible ? 'audiowrap visible' : 'audiowrap']">
        <v-row>
          <v-col cols="2" class="center p-2 mt-5">                 
              <v-btn 
              @click="play"
              fab
              small
              >
              <v-icon right class="mr-2" v-if="isplaying" size="24">
                mdi-pause
              </v-icon>
              <v-icon right class="mr-2" v-else  size="24">
                mdi-play
              </v-icon>          

              </v-btn>            
          </v-col>
          <v-col cols="10" class="">            
            <div :id="id" class="audiocontainer"></div>  
            <div :id="'spect'+id"></div>               
          </v-col>
        </v-row>              
      </div>
      <div class="loading" v-if="visible == false">  
        <v-row>
          <v-col cols="2" class="center">            
          </v-col>
          <v-col cols="10" class="p-2">            
            <div :style="'padding-top:6px;padding-bottom:10px;border-top:4px solid orange;width:'+percent+'%'">
            {{ $t('loading') }}
            </div>            
          </v-col>        
        </v-row>         
      </div>
  </div>
</template>

<script>

  import WaveSurfer from 'wavesurfer.js';
  import Spectrogram from 'wavesurfer.js/dist/plugin/wavesurfer.spectrogram';

  export default {
    props: {
        ownaudiofile: {
            type: Blob
        },
        audiofile: {
            type: String
        },
        set: {
            type: String
        },
        id: {
            type: String
        }
    },
    components: {
      
      
    },
    data () {
      return {
          wavesurfer: null,
          wavesurfer2: null,
          isplaying: false,
          visible: false,
          percent: 0,
          path: null,
          audiooptions: {
          }
      }
    },
    mounted: function() {

      this.startplayer();

    },
    computed: {
      setresultfile() {        
        console.log("COMPUTED AUDIO WAVE");
        console.log(this.set);
        console.log(this.audiofile);
        return this.audiofile;
      }
    },
    methods:{

      startplayer () {

        var self = this;

        console.log("MOUNTED AUDIO WAVE and create");
        
        this.wavesurfer = WaveSurfer.create({
          container: '#'+self.id,
          backend: 'WebAudio',
          mediaControls: false,
          splitChannels: true,        
          responsive: true,
          waveColor: '#FFCD00',          
          progressColor: '#46B54D',
          height:100,
          
          plugins: [
            Spectrogram.create({
              wavesurfer: self.wavesurfer,
              //container: '#'+self.id,
              container: '#spect'+self.id,
              height:40,
              labels: true,
            })
          ] 
          
        });

        /* Load resut file */    
        setTimeout(() => {
            //console.log("mit verzögerung laden - in id: " + self.id);            
            if(self.set) {
              console.log("load file from server");              
              var xxx = self.$config.tb_host+"get_audio?set="+self.set+"&file="+self.audiofile;
              
              if(self.set == "results") {                   
                this.wavesurfer.load(xxx);              
              } else {
                this.wavesurfer.load(xxx);                
              }
              
            } else {
              console.log("load file locally");
              console.log("filename: "+self.ownaudiofile);
              this.wavesurfer.loadBlob(self.ownaudiofile);
            }
            
            //this.wavesurfer.load(self.audiofilex);   
            this.wavesurfer.on('loading', function (result) {
              if(result < 100) self.visible = false;
              self.percent = result;
              //console.log(result);
            });             
            this.wavesurfer.on('ready', function () {
              console.log("loading ready");                         
              self.visible = true;
              console.log(this.wavesurfer);
            }); 

        },50)   

      },

      play() {
          console.log("PLAY wavesurfer");
          this.wavesurfer.playPause();  
          console.log("is playing??");
          this.isplaying = ( this.wavesurfer.isPlaying() ? true : false);
          console.log(this.isplaying);                   
      }

    },
    watch: {
      set: function (val) {
        this.path = this.$config.tb_host+"get_audio?set="+val+"&file="+self.audiofile;
      },
      audiofile: function (val) {
        console.log("watch audiofile");
        this.path = this.$config.tb_host+"get_audio?set="+this.set+"&file="+val;
        console.log(this.path);
        console.log("FIRE NEW WAVE");
        this.wavesurfer.destroy(); 
        this.startplayer();
      }
    }    
  }
</script>

<style scoped>
.audiocontainer {
  padding:4px 0px;
  background-color: #eeeeee;
  margin-bottom: 5px;
}
.audiocontainer wave {
  height:220px !important;
}
.audiowrap {  
  height:0;
  overflow:hidden;
}
.audiowrap.visible {  
  height:auto;
}
.loading {  
  background:#f7f7f7;
}
</style>
