<template>
<div>

  <div class="vwrap">

    <div v-if="result.data.evaluation">

      <div v-for="(table,index) in result.data.evaluation.diff_tables" :key="index" class="result">
          
          <div class="item vcol" v-for="(item,key) in table" :key="key">
            
            <div class="v1">
              <p>{{ item.text }}&nbsp;</p>
            </div>

            <div class="v2 lightyellow" v-if="item.operation == 'substitution'">
              <v-icon
                color="#666"
                size="14"
                class="m-2"
                >mdi-not-equal-variant</v-icon>
            </div>
            <div class="v2 lightgreen" v-else-if="item.operation == 'insertion'">
              <v-icon
                color="#666"
                size="14"
                class="m-2"
                >mdi-arrow-down</v-icon>
            </div>            
            <div class="v2 lightgrey" v-else-if="item.operation == 'nothing'">
              <v-icon
                color="#666"
                size="14"
                class="m-2"
                >mdi-arrow-down</v-icon>
            </div>
            <div class="v2 lightred" v-else-if="item.operation == 'deletion'">
              <v-icon
                color="#666"
                size="14"
                class="m-2"
                >mdi-minus</v-icon>
            </div>
            <div class="v2" v-else>
              <p>&nbsp;</p>              
            </div>            

            <div class="v3">
              <p>{{ item.hypothesis }}&nbsp;</p>
            </div>

          </div>

      </div>

    </div>

    <div v-else>

      <div v-for="(table,index) in result.data.diff_tables" :key="index" class="result">
          <div class="item vcol" v-for="(item,key) in table" :key="key">            
          <div class="v1">{{ item.text }}</div>
          <div class="v2">{{ item.operation }}</div>
          <div class="v3">{{ item.hypothesis }}</div>
          </div>
      </div>
      
    </div>

  </div>

</div>
</template>

<script>

export default {
  components: {

  },
  props: {
      result: {
          type: Object
      }
  },
  created() {
    //window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    //window.removeEventListener("resize", this.myEventHandler);
  },
  mounted: function() {
    //console.log("result in NLPResult");
    //console.log(this.result);
  },
  methods: {
    myEventHandler() {
      //console.log("RESIze")
      //console.log(window.innerHeight);
      //document.querySelector("div.v-pano").style.height = (window.innerHeight - 70) + 'px';
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.vwrap {
  margin-top:20px;
  background-color:#fff;
}
.vcol:first-child {
  /*border-left:1px solid #ddd;*/
}
.vcol {
  float:left;
  font-size:0.8rem;
  width:auto;
  height:9rem;
  padding-right:4px;
  border-right:1px solid #ddd;
}
.vcol > div {
  display:block;
  padding:14px 6px;
  height:auto;
}
.vcol > div.v1 {
  border-bottom:0px solid #ddd;
  background-color:#f7f7f7;
}
.vcol > div.v2 {
  border-bottom:0px solid #ddd;
}
.vcol > div.v3 {
  
}
/*
.result {
  clear:both;
  border-left:1px solid #ddd;
  margin-bottom:30px;
}
.result::after {
  clear:both;
  display:block;
  content: " ";
  height:0;
}
*/

/* evaluation box */

.result {
  display: -webkit-box;      
  display: -moz-box;         
  display: -ms-flexbox;     
  display: -webkit-flex;     
  display: flex;    
  overflow-x: auto;
  background-color: #F7f7f7;
  padding:10px 6px;
/*
  flex-flow:row;  
  height:100%;
  position:absolute;
  width:100%;
  */
  /*flex-wrap:wrap;*/
}
.result::-webkit-scrollbar {    
  /*display: none;*/
}
.result .item {
  padding:0;
  /*
  padding: 20px;
  color:white;
  font-size:22px;
  
  border:1px solid white;
  flex:1;
  -webkit-flex:1;
  text-align:center;
  min-width:200px;
  */
}
.vcol > div {

}
.vcol p {
  margin:0 !important;
  padding:0;
  line-height:14px;
  min-height:14px;  
}

.lightyellow {
  background-color:lightyellow;
}
.lightgreen {
  background-color: lightgreen;
}
.lightred {
  background-color: #F5B7B1;
}
.lightgrey {
  background-color: #eee;
}
</style>
