import Vue from 'vue';
import VueRouter from 'vue-router'
import App from './App.vue';
import "bootstrap";
import {library} from "@fortawesome/fontawesome-svg-core";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css/animate.css";
import vuetify from '@/plugins/vuetify';
import VueI18n from 'vue-i18n';
import messages from './lang';
import Vue2Editor from 'vue2-editor';
import Toasted from 'vue-toasted';
import AudioRecorder from 'vue-audio-recorder';

// tmp deactive
import VueRecord from '@codekraft-studio/vue-record';
import VueWaveSurfer from 'vue-wave-surfer';
import VModal from 'vue-js-modal';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import VueLazyload from 'vue-lazyload';
import axios from 'axios';
import VueAxios from 'vue-axios';
//import VueCookies from 'vue-cookies';
import VueFetch from 'vue-fetch'

import store from './helper/store.js';

import {
  faBrain,
  faBoxes
} from "@fortawesome/free-solid-svg-icons";

import './assets/css/tagsinput.css';
import './assets/css/style.css';

import NLP from "./components/NLP.vue";

library.add(faBrain, faBoxes);

Vue.prototype.$host = 'icampus';

Vue.prototype.$template = 'default';
Vue.prototype.$panoData = [];
var $home = "";
Vue.prototype.$home = $home;
Vue.prototype.$config = {
    host: process.env.VUE_APP_BACKEND_HOST || "https://testbeddev-backend.innohub13.de",
    imagepath: location.origin + '/files/images/',
    graphpath: 'https://icampus.th-wildau.de/vr/graf/',
    tb_host: process.env.VUE_APP_DSP_API_HOST || 'https://rosass.th-wildau.de/',
};

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages
});

Vue.use(VueRouter);
Vue.use(Vue2Editor);
Vue.use(VModal);
Vue.use(VueAxios, axios);
Vue.use(VueFetch, {
    polyfill: true
  });
Vue.use(VueLazyload);
var toastoptions = {
    position: 'top-center',
    duration: 4000
}
Vue.use(Toasted, toastoptions);
Vue.use(AudioRecorder);

// tmp deactive
Vue.use(VueRecord);
Vue.use(VueWaveSurfer);

//Vue.use(VueCookies);
Vue.use(VueFilterDateFormat);
Vue.use(VueFilterDateParse);

//Generate random token:
const token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
Vue.prototype.$token = token;

// set global cookie
//Vue.$cookies.config('7d','','',true, 'Lax');
//Vue.$cookies.set('USERID',token,null,null,null,null,'Lax');
//Vue.$cookies.set('USERID',token,'7d','.innohub13.de' );

/*
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  //document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=th-wildau.de; Secure";
  document.cookie = cname + "=" + cvalue + ";" + expires + "; Secure; SameSite=None";
}
*/
//setCookie('USERID',token,7);

axios.defaults.withCredentials = true;

store.commit('clearUser');

Vue.config.productionTip = false;

const router = new VueRouter({
  mode: 'history',
  routes: [    
    /*
    {
      path: "/"+$home,
      component: Home,
      name: "Home"
    },
    */    
    {      
      path: "/"+$home,
      component: NLP,
      name: "NLP"
    }
  ],
  scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export const eventBus = new Vue();

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
